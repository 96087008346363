@use '../variables';

// Responsive

@mixin device($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin content-wrapper() {
  max-width: $content-width;
  width: 100%;
  padding: 25px 40px;
  margin: 0 auto;

  @include device('medium') {
    max-width: 100%;
  }

  @include device('small') {
    padding: 10px 20px;
  }
}
