@use '../../assets/styles/app';

.wrapper {
  position: relative;
  z-index: 1;
  white-space: nowrap;

  .image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    transition: opacity app.$long-timing app.$easing,
      width app.$long-timing app.$easing;
  }

  @include app.device('medium') {
    color: app.$light-green;
    transition: color app.$long-timing app.$easing;

    .image {
      display: none;
    }
  }

  &.hidden {
    .image {
      opacity: 0;
      width: 0 !important;
    }

    @include app.device('medium') {
      color: app.$text-color;
    }
  }
}
