@use '../variables';
@use 'responsive';

// Typography
@mixin splash($device: '') {
  font-weight: 700;
  font-size: 196px;
  line-height: 196px;

  @if $device == '' {
    @include device('medium') {
      font-size: 150px;
      line-height: 150px;
    }

    @include device('small') {
      font-size: 90px;
      line-height: 90px;
    }
  } @else {
    @if $device == 'medium' {
      font-size: 150px;
      line-height: 150px;
    }

    @if $device == 'small' {
      font-size: 90px;
      line-height: 90px;
    }
  }
}

@mixin headline0($device: '') {
  font-size: 100px;
  font-weight: 700;
  line-height: 120px;

  @if $device == '' {
    @include device('medium') {
      // no change
    }

    @include device('small') {
      font-size: 80px;
      line-height: 100px;
    }
  } @else {
    @if $device == 'medium' {
      // no change
    }

    @if $device == 'small' {
      font-size: 80px;
      line-height: 100px;
    }
  }
}

@mixin headline1() {
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;

  @include device('medium') {
    font-size: 56px;
    line-height: 60px;
  }

  @include device('small') {
    font-size: 36px;
    line-height: 40px;
  }
}

@mixin headline2() {
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;

  @include device('medium') {
    font-size: 40px;
    line-height: 55px;
  }

  @include device('small') {
    font-size: 24px;
    line-height: 33px;
  }
}

@mixin headline3() {
  font-weight: 400;
  font-size: 56px;
  line-height: 76px;

  @include device('medium') {
    font-size: 40px;
    line-height: 55px;
  }

  @include device('small') {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  }
}

@mixin headline4() {
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;

  @include device('medium') {
    font-size: 28px;
    line-height: 42px;
  }

  @include device('small') {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
}

@mixin headline5() {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  @include device('medium') {
    font-size: 20px;
    line-height: 28px;
  }

  @include device('small') {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin subtitle1() {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;

  @include device('medium') {
    font-size: 20px;
    line-height: 26px;
  }

  @include device('small') {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin subtitle2() {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  @include device('medium') {
    font-size: 14px;
    line-height: 18px;
  }

  @include device('small') {
    font-size: 11px;
    line-height: 15px;
  }
}

@mixin body1() {
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;

  @include device('medium') {
    font-size: 28px;
    line-height: 36px;
  }

  @include device('small') {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin body2() {
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;

  @include device('medium') {
    font-size: 24px;
    line-height: 38px;
  }

  @include device('small') {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin caption1() {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;

  @include device('medium') {
    font-size: 18px;
    line-height: 24px;
  }

  @include device('small') {
    font-size: 14px;
    line-height: 19px;
  }
}

@mixin caption2() {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  @include device('medium') {
    font-size: 16px;
    line-height: 22px;
  }

  @include device('small') {
    font-size: 14px;
    line-height: 19px;
  }
}

@mixin caption3() {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @include device('medium') {
    font-size: 14px;
    line-height: 20px;
  }

  @include device('small') {
    font-size: 12px;
    line-height: 16px;
  }
}

@mixin button() {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;

  @include device('medium') {
    font-size: 20px;
    line-height: 28px;
  }

  @include device('small') {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin button2() {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;

  @include device('medium') {
    font-size: 20px;
    line-height: 28px;
  }

  @include device('small') {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin button3() {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  @include device('medium') {
    font-size: 14px;
    line-height: 20px;
  }

  @include device('small') {
    font-size: 12px;
    line-height: 16px;
  }
}

// Project details
@mixin project-headline() {
  font-weight: 600;
  font-size: 112px;

  @include device('medium') {
    font-size: 90px;
  }

  @include device('small') {
    font-size: 60px;
  }
}

@mixin project-subtitle() {
  font-weight: 600;
  font-size: 48px;

  @include device('medium') {
    font-size: 36px;
  }

  @include device('small') {
    font-size: 24px;
  }
}

@mixin project-header-desc() {
  font-size: 28px;
  line-height: 39px;

  @include device('medium') {
    font-size: 24px;
    line-height: 35px;
  }

  @include device('small') {
    font-size: 16px;
    line-height: 25px;
  }
}

@mixin project-catchphrase() {
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;

  @include device('medium') {
    font-size: 28px;
    line-height: 42px;
  }

  @include device('small') {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
}

// Border
@mixin bordered($color, $width: 1px) {
  text-shadow: (-$width) (-$width) 0 $light-green,
    $width (-$width) 0 $light-green, (-$width) $width 0 $light-green,
    $width $width 0 $light-green;
}
