@use '../assets/styles/app';

@keyframes main-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.main {
  margin-top: app.$header-size;
  min-height: app.$min-size;
  display: flex;
  flex-direction: column;
  animation: main-in app.$timing app.$easing;
}
