@use '../../assets/styles/app';

.wrapper {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;

  span {
    color: app.$text-lighter-color;
    @include app.subtitle1;
  }
}
