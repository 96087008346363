@use '../app';

// Classes for custom HTML
.bold {
  font-weight: 700 !important;
}

.highlight {
  color: app.$light-green !important;
}

.quote {
  font-weight: 700 !important;
  font-size: 40px !important;
  line-height: 55px !important;

  @include app.device('medium') {
    font-size: 28px !important;
    line-height: 36px !important;
  }

  @include app.device('small') {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
