@use '../../assets/styles/app';

@keyframes footer-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 56px;
  font-family: app.$font-alt-stack;
  color: app.$text-contrast-color;
  position: relative;
  background-color: app.$bg-footer-color;
  animation: footer-in app.$timing app.$easing;

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: url('../../assets/images/footer-bg.webp') no-repeat right center;
    opacity: 0.25;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .social {
      display: flex;
      flex-direction: row;
      gap: 8px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .mentions {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: app.$footer-color;
    }
  }

  @include app.device('medium') {
    padding: 36px;
  }

  @include app.device('small') {
    padding: 24px 16px;
    gap: 32px;

    &::before {
      background-size: cover;
      background-position: center center;
    }

    .row {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
}
