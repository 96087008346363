/* https://gwfh.mranftl.com/fonts/nunito?subsets=latin */

/* nunito-regular - latin */
@font-face {
  font-family: nunito;
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../fonts/nunito-v25-latin-regular.woff2') format('woff2'),
    url('../../fonts/nunito-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-600 - latin */
@font-face {
  font-family: nunito;
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../../fonts/nunito-v25-latin-600.woff2') format('woff2'),
    url('../../fonts/nunito-v25-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-700 - latin */
@font-face {
  font-family: nunito;
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../../fonts/nunito-v25-latin-700.woff2') format('woff2'),
    url('../../fonts/nunito-v25-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-800 - latin */
@font-face {
  font-family: nunito;
  font-style: normal;
  font-weight: 800;
  src: local(''), url('../../fonts/nunito-v25-latin-800.woff2') format('woff2'),
    url('../../fonts/nunito-v25-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
