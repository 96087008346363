@use '../../../assets/styles/app';

.wrapper {
  .inner {
    width: 100%;
    max-width: app.$content-width;
    display: flex;
    flex-direction: row;
    justify-content: column;
    padding: 120px 80px 160px;
    margin: 0 auto;
    position: relative;

    .responsive {
      display: none;
      @include app.headline2;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include app.image-after(
        162px,
        110px,
        315px,
        372px,
        '../../../../assets/images/home/arrow-projects.png',
        '../../../../assets/images/home/arrow-projects@2x.png'
      );

      .title {
        margin-bottom: 50px;
        @include app.headline2;
      }

      .text {
        width: 100%;
        max-width: 480px;
        margin-bottom: 130px;
        @include app.body1;
      }
    }

    .previews {
      position: absolute;
      top: 0;
      right: 0;
      width: 840px;
      height: 720px;
      transform-origin: top right;

      img {
        position: absolute;

        &:nth-child(1) {
          top: 62px;
          right: 575px;
        }

        &:nth-child(2) {
          top: 18px;
          right: 386px;
        }

        &:nth-child(3) {
          top: 39px;
          right: 157px;
        }

        &:nth-child(4) {
          top: 265px;
          right: 624px;
        }

        &:nth-child(5) {
          top: 279px;
          right: 300px;
        }

        &:nth-child(6) {
          top: 233px;
          right: 90px;
        }

        &:nth-child(7) {
          top: 460px;
          right: 610px;
        }

        &:nth-child(8) {
          top: 514px;
          right: 347px;
        }

        &:nth-child(9) {
          top: 577px;
          right: 150px;
        }

        &:nth-child(10) {
          top: 396px;
          right: 47px;
        }
      }
    }
  }

  @include app.device('x-large') {
    .inner {
      .previews {
        top: 60px;
        right: 30px;
        transform: scale(0.85);
      }
    }
  }

  @include app.device('large') {
    .inner {
      .previews {
        top: 160px;
        transform: scale(0.6);
      }
    }
  }

  @include app.device('medium') {
    .inner {
      padding: 80px;
      flex-direction: column;
      align-items: center;
      gap: 60px;

      .responsive {
        width: 100%;
        display: block;
      }

      .content {
        align-items: center;

        .title {
          display: none;
        }

        &::after {
          display: none;
        }

        .text {
          max-width: 100%;
          margin-bottom: 60px;
        }
      }

      .previews {
        top: 0;
        right: 0;
        position: relative;
        width: 840px * 0.7;
        height: 720px * 0.7;
        transform: scale(0.7);
      }
    }
  }

  @include app.device('small') {
    .inner {
      padding: 30px;
      gap: 30px;

      .content {
        .text {
          margin-bottom: 10px;
        }

        .cta {
          width: 100%;
        }
      }

      .previews {
        width: 840px * 0.4;
        height: 720px * 0.4;
        transform: scale(0.4);
      }
    }
  }
}
