@use '../../../assets/styles/app';

.wrapper {
  background-color: app.$bg-alt-color;

  .inner {
    width: 100%;
    max-width: app.$content-width;
    padding: 80px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 80px;

    .responsive {
      display: none;
      @include app.headline2;
    }

    .animation {
      min-width: 544px;
      width: 544px;
      height: auto;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      max-width: 620px;

      .title {
        @include app.headline2;
      }

      .text {
        @include app.body1;
      }
    }
  }

  @include app.device('large') {
    .inner {
      .animation {
        min-width: 544px * 0.8;
        width: 544px * 0.8;
      }
    }
  }

  @include app.device('medium') {
    .inner {
      .animation {
        min-width: 544px * 0.6;
        width: 544px * 0.6;
      }
    }
  }

  @include app.device('small') {
    .inner {
      padding: 30px;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .responsive {
        width: 100%;
        display: block;
      }

      .animation {
        min-width: 544px * 0.4;
        width: 544px * 0.4;
      }

      .content {
        max-width: 100%;

        .title {
          display: none;
        }

        .text {
          margin-bottom: -30px;
        }
      }
    }
  }
}
