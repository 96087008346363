@use '../../assets/styles/app';

.wrapper {
  width: 100%;
  max-width: app.$content-width;
  margin: 0 auto;

  .header {
    display: flex;
    flex-direction: column;
    height: 150px;
    padding: 0 40px;
    position: relative;
    overflow: hidden;

    .animation {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .title {
      color: white;
      margin: 15px 0;
      @include app.headline0;
      @include app.bordered(app.$light-green, 1px);

      @include app.device('small') {
        font-size: 80px;
      }
    }

    .baseline {
      display: none;
      width: 100%;
      max-width: 500px;
      margin-right: 40px;
      align-self: flex-end;
      @include app.body2;

      @include app.device('medium') {
        max-width: 380px !important;
      }
    }
  }

  .content {
    padding: 25px 40px;
  }

  &.animate {
    .header {
      height: 820px;
      animation: header-animation app.$header-timing app.$easing app.$timing
        forwards;

      @include app.device('medium') {
        height: 680px;
        animation: header-animation-medium app.$header-timing app.$easing
          app.$timing forwards;
      }

      @include app.device('small') {
        animation: header-animation-small app.$header-timing app.$easing
          app.$timing forwards;
      }

      .animation {
        display: block;
        opacity: 0;
        animation: background-animation app.$header-timing app.$easing
          app.$timing forwards;

        @include app.device('medium') {
          animation: background-animation-medium app.$header-timing app.$easing
            app.$timing forwards;
        }

        @include app.device('small') {
          animation: background-animation-small app.$header-timing app.$easing
            app.$timing forwards;
        }
      }

      .title {
        color: app.$light-green;
        margin: 260px 0 60px;
        opacity: 0;
        animation: title-animation app.$header-timing app.$easing app.$timing
          forwards;
        @include app.splash;

        @include app.device('medium') {
          margin: 240px 0 60px;
          animation: title-animation-medium app.$header-timing app.$easing
            app.$timing forwards;
        }

        @include app.device('small') {
          animation: title-animation-small app.$header-timing app.$easing
            app.$timing forwards;
        }
      }

      .baseline {
        display: block;
        opacity: 0;
        animation: baseline-animation app.$header-timing app.$easing app.$timing
          forwards;

        @include app.device('medium') {
          animation: baseline-animation-medium app.$header-timing app.$easing
            app.$timing forwards;
        }

        @include app.device('small') {
          animation: baseline-animation-small app.$header-timing app.$easing
            app.$timing forwards;
        }
      }
    }
  }
}

// 0% blanc
// 10% fadein title + baseline 0.3s
// 20% apparition highlight 0.3s
// 70% anim bg + attente 1.5s ?
// 80% disparition des elements + couleur titre 0.3s
// 100% resize vers position initiale 0.6s
@mixin header-animation($name, $device: '') {
  @keyframes #{$name} {
    #{app.header-frame(0.3s)} {
      // fadein title + baseline 0.3s
    }

    #{app.header-frame(0.6s)} {
      // apparition highlight 0.3s
    }

    #{app.header-frame(calc(app.$header-timing - 0.9s))} {
      // anim bg + attente 1.5s ?
    }

    #{app.header-frame(calc(app.$header-timing - 0.6s))} {
      // disparition des elements + couleur titre 0.3s
      height: 820px;

      @if $device == 'medium' {
        height: 680px;
      }
    }

    100% {
      // resize vers position initiale 0.6s
      height: 150px;
    }
  }
}

@mixin title-animation($name, $device: '') {
  @keyframes #{$name} {
    #{app.header-frame(0.3s)} {
      // fadein title + baseline 0.3s
      opacity: 1;
    }

    #{app.header-frame(0.6s)} {
      // apparition highlight 0.3s
      opacity: 1;
    }

    #{app.header-frame(calc(app.$header-timing - 0.9s))} {
      // anim bg + attente 1.5s ?
      color: app.$light-green;
      opacity: 1;
    }

    #{app.header-frame(calc(app.$header-timing - 0.6s))} {
      // disparition des elements + couleur titre 0.3s
      color: white;
      margin: 260px 0 60px;
      opacity: 1;
      @include app.splash($device);

      @if $device == 'medium' {
        margin: 240px 0 60px;
      }
    }

    100% {
      // resize vers position initiale 0.6s
      opacity: 1;
      color: white;
      margin: 15px 0;
      @include app.headline0($device);
    }
  }
}

@mixin baseline-animation($name, $device: '') {
  @keyframes #{$name} {
    #{app.header-frame(0.3s)} {
      // fadein title + baseline 0.3s
      opacity: 1;
    }

    #{app.header-frame(0.6s)} {
      // apparition highlight 0.3s
      opacity: 1;
    }

    #{app.header-frame(calc(app.$header-timing - 0.9s))} {
      // anim bg + attente 1.5s ?
      opacity: 1;
    }

    #{app.header-frame(calc(app.$header-timing - 0.6s))} {
      // disparition des elements + couleur titre 0.3s
      opacity: 0;
    }

    100% {
      // resize vers position initiale 0.6s
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
}

@mixin background-animation($name, $device: '') {
  @keyframes #{$name} {
    #{app.header-frame(0.3s)} {
      // fadein title + baseline 0.3s
      opacity: 0;
    }

    #{app.header-frame(0.6s)} {
      // apparition highlight 0.3s
      opacity: 1;
    }

    #{app.header-frame(calc(app.$header-timing - 0.9s))} {
      // anim bg + attente 1.5s ?
      opacity: 1;
    }

    #{app.header-frame(calc(app.$header-timing - 0.6s))} {
      // disparition des elements + couleur titre 0.3s
      opacity: 0;
    }

    100% {
      // resize vers position initiale 0.6s
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
}

@include header-animation(header-animation);
@include header-animation(header-animation-medium, 'medium');

@include title-animation(title-animation);
@include title-animation(title-animation-medium, 'medium');

@include baseline-animation(baseline-animation);
@include baseline-animation(baseline-animation-medium, 'medium');

@include background-animation(background-animation);
@include background-animation(background-animation-medium, 'medium');
