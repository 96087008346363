@use '../../../assets/styles/app';

.wrapper {
  background-color: app.$bg-header-color;
  color: app.$text-contrast-color;
  position: relative;
  height: 870px;
  overflow: hidden;

  .hero {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../../assets/images/home/hero-min.png') no-repeat 50%
      50% / cover;

    &::after {
      content: '';
      position: absolute;
      background-color: rgb(0 0 0 / 30%);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: app.$content-width;
    height: 100%;
    padding: 80px;
    margin: 0 auto;

    .content {
      display: flex;
      flex-direction: column;
      z-index: 1;

      .baseline {
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 15px;
        @include app.body1;
      }

      h1 {
        width: 100%;
        max-width: 540px;
        margin-bottom: 60px;
        @include app.headline1;

        @include app.image-after(
          431px,
          523px,
          -78px,
          53px,
          '../../../../assets/images/home/arrow-design.png',
          '../../../../assets/images/home/arrow-design@2x.png'
        );

        .shortname {
          display: none;
        }
      }

      .intro {
        width: 100%;
        max-width: 540px;
        @include app.body2;
      }
    }
  }

  @include app.device('medium') {
    height: 640px;

    .inner {
      .content {
        h1 {
          @include app.image-after(
            431px * 0.75,
            523px * 0.75,
            -72px,
            65px,
            '../../../../assets/images/home/arrow-design.png',
            '../../../../assets/images/home/arrow-design@2x.png'
          );
        }
      }
    }
  }

  @include app.device('small') {
    height: 340px;

    .hero {
      background: url('../../../assets/images/home/hero-mobile-min.png')
        no-repeat 0 50% / cover;
    }

    .inner {
      justify-content: flex-start;
      padding: 30px;

      .content {
        h1 {
          margin-bottom: 35px;

          @include app.image-after(
            431px * 0.5,
            523px * 0.5,
            -38px,
            10px,
            '../../../../assets/images/home/arrow-design.png',
            '../../../../assets/images/home/arrow-design@2x.png'
          );

          .longname {
            display: none;
          }

          .shortname {
            display: inline;
          }
        }

        .intro {
          max-width: 330px;
          padding-left: 15px;
        }
      }
    }
  }
}
