@use '../../assets/styles/app';

.image {
  opacity: 1;
  animation: fade-in app.$quick-timing app.$easing forwards;

  &.hidden {
    opacity: 0;
    animation: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
