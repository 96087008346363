@use '../../assets/styles/app';

@keyframes header-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  width: 100%;
  height: app.$header-size;
  background-color: app.$bg-color;
  align-items: center;
  justify-content: space-between;
  animation: header-in app.$timing app.$easing;
  padding: 0 16px 0 0;

  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .logo {
      width: 200px;
      min-width: 200px;
      height: 100%;
      background-color: app.$fluo-green;
      display: flex;
      align-items: center;
      justify-content: center;

      .small {
        display: none;
      }

      @include app.device('medium') {
        width: 68px;
        min-width: 68px;

        .normal {
          display: none;
        }

        .small {
          display: block;
        }
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      justify-content: center;

      @include app.device('small') {
        display: none;
      }
    }
  }

  .brand {
    font-size: 20px;
    text-decoration: none;
    color: app.$light-green;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    white-space: nowrap;

    img {
      width: 36px;
      height: 36px;
      transition: opacity app.$timing app.$easing;

      &.in {
        opacity: 1;
      }

      &.out {
        opacity: 0;
      }
    }

    .bold {
      font-weight: 700;
    }

    @include app.device('small') {
      font-size: 16px;
    }
  }

  .burger {
    display: none;

    @include app.device('small') {
      display: block;
    }
  }
}

.panel {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: app.$header-size;
  height: calc(100vh - app.$header-size);
  display: none;

  @include app.device('small') {
    display: block;
  }
}
