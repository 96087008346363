@use './assets/styles/app';

.wrapper {
  main {
    animation-duration: app.$timing;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  &.in {
    main {
      animation-name: fade-in;
    }
  }

  &.out {
    main {
      animation-name: fade-out;
    }
  }

  &.left {
    &.in {
      main {
        animation-name: slide-in-left;
      }
    }

    &.out {
      main {
        animation-name: slide-out-left;
      }
    }
  }

  &.right {
    &.in {
      main {
        animation-name: slide-in-right;
      }
    }

    &.out {
      main {
        animation-name: slide-out-right;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    transform: translateX(-20px);
    opacity: 0;
  }
}

@keyframes slide-in-right {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    transform: translateX(20px);
    opacity: 0;
  }
}
