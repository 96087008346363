@use '../../assets/styles/app';

.nav {
  padding: 0 12px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;

    li {
      list-style-type: none;

      a {
        padding: 10px 12px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: app.$text-color;
        text-decoration: none;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          background-color: app.$dark-green;
          height: 5px;
          border-radius: 2.5px;
          bottom: -7px;
          left: 12px;
          right: 12px;
          opacity: 0;
          transform: scaleX(0.5);
          transition: opacity 0.25s ease-out, transform 0.25s ease-out;
          pointer-events: none;
        }

        &:hover,
        &.active {
          &::after {
            content: '';
            opacity: 1;
            transform: scaleX(1);
          }
        }
      }

      @include app.device('small') {
        display: none;

        &:first-child,
        &:last-child {
          display: block;
        }
      }
    }
  }
}
