@use '../../assets/styles/app';

.button {
  background-color: app.$dark-green;
  color: app.$text-contrast-color;
  font-family: app.$font-stack;
  text-decoration: none;
  padding: 24px 36px;
  border: 0;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: background-color app.$timing app.$easing;
  @include app.button2;

  @include app.device('medium') {
    padding: 20px 32px;
  }

  &:hover {
    background-color: app.$light-green;
  }
}
