@use '../app';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: app.$font-stack;
  background-color: app.$bg-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus,
input:focus {
  border-color: app.$green;
  /* stylelint-disable color-function-notation */
  box-shadow: 0 0 2px rgba(app.$dark-green, 0.2) inset,
    0 0 5px rgba(app.$green, 0.6);
  /* stylelint-enable color-function-notation */
  outline: 0 none;
  transition: border-color app.$quick-timing app.$easing,
    box-shadow app.$quick-timing app.$easing;
}
