@use '../../assets/styles/app';

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  background: none;
  overflow: hidden;
  color: currentcolor;
  cursor: pointer;
  transition: border-radius app.$quick-timing app.$easing;
  position: relative;

  .line {
    display: block;
    background-color: currentcolor;
    height: 2px;
    width: 12px;
    border-radius: 1px;
    position: absolute;
    transition: top app.$quick-timing app.$easing,
      transform app.$quick-timing app.$easing,
      width app.$quick-timing app.$easing, left app.$quick-timing app.$easing,
      opacity app.$quick-timing app.$easing;

    &:nth-child(1) {
      top: 15px;
      left: 14px;
    }

    &:nth-child(2) {
      top: 19px;
      left: 14px;
    }

    &:nth-child(3) {
      top: 23px;
      left: 14px;
    }
  }

  &.close {
    .line {
      &:nth-child(1) {
        top: 20px;
        width: 16px;
        left: 12px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
      }

      &:nth-child(3) {
        top: 20px;
        width: 16px;
        left: 12px;
        transform: rotate(-45deg);
      }
    }
  }
}
