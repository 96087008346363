// Positionning

@mixin image-after($width, $height, $left, $top, $url, $url-2x: '') {
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    width: $width;
    height: $height;
    position: absolute;
    left: $left;
    top: $top;
    z-index: -1;

    @if $url-2x != '' {
      background: image-set(url($url) 1x, url($url-2x) 2x)
        no-repeat
        top
        left /
        $width
        $height;
    } @else {
      background: url($url) no-repeat top left / $width $height;
    }
  }
}

// Layout

@mixin grid(
  $child-selector,
  $cols: 1,
  $horizontal-gap: 0,
  $vertical-gap: 0,
  $center: false
) {
  display: flex;
  flex-flow: row wrap;
  gap: $horizontal-gap;

  @if $center == true {
    justify-content: center;
  }

  #{$child-selector} {
    width: calc((100% - $horizontal-gap * ($cols - 1)) / $cols);
    margin-bottom: $vertical-gap - $horizontal-gap;
  }
}

@mixin delay-animation($delay: 0.05s, $limit: 10) {
  @for $i from 1 through $limit {
    &:nth-child(#{$i}) {
      animation-delay: $i * $delay;
    }
  }

  &:nth-child(n + #{$limit}) {
    animation-delay: $delay * $limit;
  }
}
