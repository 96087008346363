@use '../../assets/styles/app';

.wrapper {
  .fallback {
    display: none;
    @include app.caption3;
  }

  @include app.device('small') {
    .button {
      display: none;
    }

    .fallback {
      display: block;
    }
  }
}
