@use '../../../assets/styles/app';

.wrapper {
  background-color: app.$bg-alt-color;

  .inner {
    width: 100%;
    max-width: app.$content-width;
    padding: 80px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 80px;

    h2 {
      @include app.headline2;
    }

    .animation {
      width: 100%;
      height: auto;
    }

    .text {
      @include app.body2;

      .arrow {
        @include app.image-after(
          167px,
          157px,
          65px,
          -25px,
          '../../../../assets/images/home/arrow-situations.png',
          '../../../../assets/images/home/arrow-situations@2x.png'
        );

        @include app.device('large') {
          &::after {
            display: none;
          }
        }
      }
    }

    .cta {
      margin: 0 auto;
    }
  }

  @include app.device('medium') {
    .inner {
      gap: 60px;
    }
  }

  @include app.device('small') {
    .inner {
      padding: 30px;
      gap: 30px;

      .cta {
        width: 100%;
        margin-top: -20px;
      }
    }
  }
}
