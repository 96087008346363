@use '../../assets/styles/app';

.panel {
  background-color: app.$bg-color;
  pointer-events: none;
  opacity: 0;
  list-style: none;
  transform: translateY(-50px);
  transition: opacity app.$quick-timing app.$easing,
    transform app.$quick-timing app.$easing;

  li {
    border-bottom: solid 1px app.$light-border-color;

    &:first-child {
      border-top: solid 1px app.$light-border-color;
    }

    .link {
      display: block;
      padding: 16px;
      font-size: 16px;
      font-weight: 400;
      color: app.$text-color;
      position: relative;
      transition: color app.$quick-timing app.$easing;

      &:hover {
        color: app.$light-green;
      }
    }
  }

  &.open {
    pointer-events: all;
    opacity: 1;
    transform: none;
  }
}
