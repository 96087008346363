.ripple {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  span {
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    opacity: 0.7;
    animation-name: ripple;
    animation-timing-function: ease-in-out;
    pointer-events: none;
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
}
