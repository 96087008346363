// Colors
$fluo-green: #3dcd58;
$light-green: #00a848;
$green: #009530;
$dark-green: #008a16;
$darker-green: #007626;
$text-color: #000;
$text-light-color: #333;
$text-lighter-color: #626469;
$text-contrast-color: #fff;
$link-color: #0079a7;
$bg-color: #fff;
$bg-alt-color: #f7f7f7;
$bg-header-color: #0b0b0b;
$bg-dots-color: #e6e6e6;
$bg-footer-color: #293133;
$legend-color: #9fa0a4;
$light-border-color: #c6c6c6;
$border-color: #999;
$footer-color: #ccc;
$dark-border-color: #626469;
$hover-border-color: #000;
$filter-color: #000;

// Fonts
$font-stack: nunito, tahoma, verdana, sans-serif;
$font-alt-stack: 'Arial', sans-serif;

// Sizes
$header-size: 56px;
$min-size: calc(100vh - $header-size);
$content-width: 1440px;

// Animation
$quick-timing: 0.15s;
$timing: 0.3s;
$long-timing: 0.5s;
$easing: ease-out;

// Header specifics
$header-timing: 6s;
$animation-timing: 5.1s;
$animation-delay: 0.9s;

// Breakpoints
$breakpoints: (
  'x-small': 640px,
  'small': 768px,
  'medium': 1024px,
  'large': 1280px,
  'x-large': 1440px,
) !default;
